@use "sass:math";
@import "../../../styles/base/abstracts";

.hbs-component--tease-feed {
  // For programmatic scrolling when changing archive pagination on desktop
  @include mq($bp-desktop) {
    scroll-margin-top: calc(#{$grid-padding * 2} + #{$header-shift});
  }
}

.hbs-tease-feed {
  .hbs-article-tease--compressed {
    @include padding($spacing-md 0);
    overflow: hidden;
  }

  &:first-child {
    padding-top: 0;
  }
}

.hbs-tease-feed__header {
  color: $c-text-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-sm;
  flex-wrap: wrap;
  row-gap: $spacing-sm;
  column-gap: $spacing-xl;
}

.hbs-tease-feed__sort-info {
  align-items: center;
  display: flex;
  color: $c-text-light;

  .hbs-tease-feed__sort__select {
    font-weight: $fw-semibold;
    padding-left: $spacing-xxs;
    position: relative;

    select {
      padding-right: $spacing-lg;
    }

    &::before {
      @include pseudo(50% $spacing-sm * -1 x x);
      @include size(0.5em);
      @include margin(x $spacing-md);
      border-right: $border-solid;
      border-bottom: $border-solid;
      border-color: $c-black;
      pointer-events: none;
      transform: translateY(-90%) rotateZ(45deg);
    }
  }
}

.hbs-no-results {
  @include baseline-text;
  margin-bottom: $spacing-lg;

  p.title {
    margin: $spacing-md 0;
    font-size: $fs-lg;
    font-weight: $fw-semibold;
    margin: $font-margin-h4;
  }

  p {
    margin-bottom: $spacing-sm;
  }

  ul {
    list-style: disc;
    padding-left: $spacing-md;
  }
}
